<script lang="ts">
	import { onMount } from "svelte";

	export let value: string;
	const inputs = Array(6);

	const onInput = (inputIndex: number) => (e: any) => {
		if (!e.target) return;
		const target = (e.target as HTMLInputElement);
		value = inputs.map(x => x.value).join('');
		target.value = value[inputIndex] ?? '';
		if (value.length < 6) {
			selectInput(value.length)();
		}
		else if (value.length === 6) {
			target.blur();
		}
		else if (value.length > 6) {
			value = value.slice(0, 6);
		}
	}

	const onKeydown = (inputIndex: number) => (e: any) => {
		if (e.key === 'Backspace') {
			if (value.length === 0) return;
			value = value.slice(0, -1);
			selectInput(value.length)();
		}
	}

	// let clipboardHint = '';
	// const onWindowFocus = () => {
	// 	if (!('ontouchstart' in window)) return;
	// 	clipboardHint = 'Nebo povolte přístup k schránce pro automatické vložení ze schránky.';
	// 	navigator.clipboard.readText()
	// 		.then(text => {
	// 			clipboardHint = '';
	// 			if (text.length !== 6) return;
	// 			if (text.match(/^[0-9]+$/) === null) return;
	// 			value = text;
	// 		});
	// }

	const selectInput = (inputIndex: number) => () => {
		if (!inputs[inputIndex]) return;
		inputs[inputIndex].focus();
		inputs[inputIndex].select();
	}

	$: {
		if (value.length === 0) {
			selectInput(0)();
		}
	}

	onMount(() => {
		selectInput(0)();
	});
</script>

<!-- <svelte:window on:focus={onWindowFocus} /> -->

<div class="tfa-input">
	{#each Array(6) as _, i}
		{#if i === 3}
			<div class="tfa-input-separator">
				-
			</div>
		{/if}
		<input
			type="text"
			inputmode="numeric"
			maxlength="6"
			value={value[i] ?? ''}
			autocomplete={i === 0 ? 'one-time-code' : 'off'}
			aria-labelledby="session-otp-input-label"
			aria-describedby="session-otp-input-description"
			pattern="[0-9]*"
			on:input={onInput(i)}
			on:keydown={onKeydown(i)}
			bind:this={inputs[i]}
			on:click={selectInput(i)}
		/>
	{/each}
</div>

<style lang="scss">
	.tfa-input {
		display: flex;
		gap: 1rem;
		justify-content: space-between;
		align-items: center;

		input {
			font-size: 1.5rem;
			text-align: center;
			padding: 1rem;
			height: auto;
		}
	}

	.tfa-input-separator {
		font-size: 3rem;
	}
/*
	.clipboard-hint {
		text-align: center;
		font-size: 0.8rem;
		margin: 0.5rem;
		color: var(--var-color-text-light);
	}*/
</style>
