<script lang="ts">
	import Slider from "../../layout/Slider.svelte";

	export let value: number = 0;
	export let min: number = 0;
	export let max: number = 100;
	export let step: number = 1;

	export let title: string = '';
	export let minLabel: string = '';
	export let maxLabel: string = '';
	export let unit: string = '';

	export let onchange: undefined | (() => void) = undefined;
	function handleChange() {
		if (onchange) {
			onchange();
		}
	}
</script>

<div class="resource">
	<div class="title">{title}</div>
	<div class="configurator">
		<div class="manual-input">
			<div class="input-group">
				<input type="number" bind:value={value} min={min} max={max} step={step} on:change={handleChange} />
				<div class="unit input-group-append">{unit}</div>
			</div>
		</div>
		<div class="slider-input">
			<div class="slider-wrapper">
				<Slider bind:value={value} min={min} max={max} step={step} on:input={handleChange} />
			</div>
			<div class="labels">
				<div>{minLabel}</div>
				<div>{maxLabel}</div>
			</div>
		</div>
	</div>
</div>

<style>
	.resource {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.title {
		font-weight: semibold;
	}

	.configurator {
		display: flex;
		align-items: center;
		gap: 1.5rem
	}

	.manual-input {
		display: flex;
		align-items: center;
	}
	.manual-input input {
		width: 5rem;
	}
	.manual-input .unit {
		width: 3rem;
	}

	.slider-input {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.slider-input .labels {
		display: flex;
		justify-content: space-between;
	}
</style>
