<script lang="ts">
	import * as  toaster from '../toaster';
	import { link } from "svelte-routing";
	import Api from "../Api";
	import Captcha from "../lib/Captcha.svelte";

	let email = "";
	let password = "";
	let captchaCode: string | undefined;
	let captcha: HTMLDivElement | undefined;
	let loading = false;

	let totpcode = "";
	let usetotp = false;

	import { User } from "../store";
	import { getContext } from 'svelte';
	import { f } from '../utils/formatString';
	import TfaInput from '../layout/TfaInput.svelte';
	import { t } from '../lib/locale';
	let user: User | undefined;
	User.subscribe(x => user = x);

	const shakeF: Function = getContext('shake');
	const shake = () => {
		if (shakeF) {
			shakeF();
		}
	}

	function submitLogin() {
		// Validate
		if (email.length == 0 || password.length == 0) {
			toaster.error($t('login.error.empty'));
			shake();
			return;
		}
		if (captchaCode == null) {
			toaster.error($t('login.error.captcha'));
			shake();
			return;
		}

		loading = true;
		// Send request
		Api.call('auth/login', {
			email: email,
			password: password,
			captcha: captchaCode
		})
		.then(response => {
			if (response.success)
			{
				// Save token and finish login

				if (localStorage.getItem('sudo_token') != null)
				{
					localStorage.removeItem('sudo_token');
				}

				Api.setToken(response.token);

				if ((globalThis as any).PasswordCredential && response.user)
				{
					const cred = new (globalThis as any).PasswordCredential({
						id: email,
						password: password,
						name: response.user.username,
						icon: "https://helkor.eu/img/favicon.png"
					});
					globalThis.navigator.credentials.store(cred);
				}

				if (response.totp_enabled) {
					usetotp = true;
				} else {
					usetotp = false;

					toaster.success($t('login.message.welcome.user', {
						username: response.user.username,
					}));
					User.set(response.user);
				}

				resetForm();
				email = "";
			}
			else
			{
				const errors: Record<number, string> = {
					401: $t('login.error.password'),
				};

				toaster.error(response.message ?? errors[response.code] ?? ($t('login.error.unknown')));
				shake();
				resetForm();
			}
		})
		.catch(error => {
			// console.log(error);
			shake();
			toaster.error("500: " + $t('login.error.unknown'));
			resetForm();
		});


	}

	function resetForm()
	{
		loading = false;
		captchaCode = undefined;
		password = "";
		(globalThis as any).turnstile.reset(captcha);
	}

	function submitTotp()
	{
		loading = true;

		Api.call('auth/login/totp', {
			code: totpcode
		})
		.then(response=>{

			totpcode = "";
			loading = false;

			if (response.success) {
				toaster.success($t('login.message.welcome.user', {
					username: response.user.username
				}));
				User.set(response.user);
			} else {
				toaster.error(response.message ?? $t('login.error.unknown'));
			}
		})
		.catch(e=>{
			toaster.error("500: " + $t('login.error.unknown'));
			loading = false;
			totpcode = "";
			resetForm();
		});
	}

	$: {
		totpcode = totpcode.replace(/[^0-9]/g, '');
		if (totpcode.length === 6)
		{
			submitTotp();
		}
	}
</script>

{#if user}
	<p>{$t('login.message.logged_as')} <b>{user.username}</b></p>
	<br />
	<button class="button" on:click={User.logout}>{$t('login.action.logout')}</button>

{:else if usetotp}
	<p>{$t('login.message.two_factor')}</p>
	<form on:submit|preventDefault={submitTotp}>
		<TfaInput bind:value={totpcode} />
		{#if loading}
			<button class="button" disabled>
				<i class="fa-solid fa-circle-notch fa-spin"></i>
			</button>
		{:else}
			<input type="submit" value={$t('login.action.confirm')} />
		{/if}
	</form>
{:else}

<p>{$t('login.message.login_to_continue')}</p>

<form on:submit|preventDefault={submitLogin}>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-envelope"></i>
		</div>
		<input bind:value={email} type="email" placeholder={$t('login.field.email')} required />
	</div>
	<div class="input-group">
		<div class="input-group-prepend">
			<i class="fas fa-lock"></i>
		</div>
		<input bind:value={password} type="password" placeholder={$t('login.field.password')} required />
	</div>

	<Captcha bind:captcha={captcha} bind:value={captchaCode} />

	<div>
		<div class="actions">
			<a href="/reset-password?back={encodeURIComponent(location.pathname)}" use:link>{$t('login.action.forgot_password')}</a>
			<a href="/register?back={encodeURIComponent(location.pathname)}" use:link>{$t('login.action.register')}</a>
		</div>
		<div class="submit">
			{#if loading}
				<button class="button" disabled>
					<i class="fa-solid fa-circle-notch fa-spin"></i>
				</button>
			{:else}
				<input type="submit" name="submit" value={$t('login.action.login')} />
			{/if}

		</div>
	</div>

</form>

{/if}

<style>
	p {
		text-align: center;
	}

	.actions {
		width: 60%;
		display: inline-block;
		box-sizing: border-box;
	}
	.submit {
		width: 40%;
		box-sizing: border-box;
		float: right;
	}

	.actions a {
		display: block;
		text-decoration: none;
		margin: 0.4rem 0;
		font-size: .9rem;
		font-weight: bold;
	}

	input, .input-group-prepend, .button {
		border: 1px solid transparent;
	}

	/*.error {
		background: #f44336;
		padding: 0.3rem 1em;
		text-align: center;
		border-radius: 5px;
	}*/
</style>
