export enum DomainConfigState {
	PENDING = "PENDING",
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
}

export type DomainConfig = {
	id: string;
	ownerId: number;
	domain: string;
	state: DomainConfigState;
	ttl: number;
	createAt: string;
	updatedAt: string;
	checkAt: string | null;
	registration?: null | DomainRegistration;
	access?: DomainAccess[];
	reverseProxyRecords?: ReverseProxyRecord[];
};

export type DomainLogRecord = {
	createdAt: string;
	domainId: string;
	id: number;
	message: string;
	type: string;
	userId: number;
}

export enum ReverseProxyRecordType {
	REDIRECT = "REDIRECT",
	HTTP_PROXY = "HTTP_PROXY",
}

export type ReverseProxyRecord = {
	id: string;
	type: ReverseProxyRecordType;
	domainId: string;
	domain?: DomainConfig;
	subdomain: string;
	target: string;
	forceSSL: boolean;
	dnsOk: boolean;
	sslOk: boolean;
	createdAt: string;
	updatedAt: string;
	lastCheck: string;
};

export type DomainAccess = {

};

export type DomainRegistration = {

};
