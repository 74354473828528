<script lang="ts">
	import { t } from "../../lib/locale";

	type OrderOverviewItemParam = {
		title: string;
		value: string;
		hidden?: boolean;
	}

	type OrderOverviewItem = {
		title: string;
		price: string;
		priceNote?: string;
		params?: OrderOverviewItemParam[];
	}

	type OrderOverviewGroup = {
		title: string;
		items: OrderOverviewItem[];
	};

	type OrderOverviewData = {
		groups: OrderOverviewGroup[];
	};

	export let data: OrderOverviewData;

	export let error: string | undefined = undefined;
	export let submit: () => void;
</script>

<div class="order-overview">
	<h2>{$t('order.summary.title')}</h2>

	{#each data.groups as group}
		<h3 class="item-group-title">{group.title}</h3>
		{#each group.items as item}
			<div class="item">
				<div class="item__info">
					<div class="item__title">
						{item.title}
					</div>
					<div class="item__params">
						{#each item.params ?? [] as param}
							{#if !param.hidden}
								<div class="param">
									<div>{param.title}:</div>
									<div>{param.value}</div>
								</div>
							{/if}
						{/each}
					</div>
				</div>
				<div class="item__price">
					<div>{item.price}</div>
					{#if item.priceNote}
						<div>{item.priceNote}</div>
					{/if}
				</div>
			</div>
		{/each}
	{/each}

	<button
		class="button success button--order"
		disabled={!!error}
		data-balloon-pos="up"
		aria-label={error}
		on:click={submit}
	>
		<i class="fas fa-check"></i>
		{$t('order.submit')}
	</button>
</div>

<style>
	.order-overview {
		position: sticky;
		margin-top: 1.5rem;
		top: 1rem;
		background-color: var(--var-color-border);
		border-radius: 5px;
		padding: .5rem 1.5rem;
	}

	.item {
		display: flex;
		justify-content: space-between;
	}

	.item-group-title {
		margin-bottom: .25rem;
	}
	.item__title {
		font-size: 1rem;
		font-weight: 500;
	}
	.item__params {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		margin-top: .5rem;
		width: 100%;
	}

	.param {
		color: var(--var-color-text-light);
	}

	.item__price {
		color: var(--var-color-text-light);
		text-align: right;
	}
	.item__price div:first-child {
		font-size: 1rem;
		color: white;
	}

	.button--order {
		margin-top: 1rem;
	}

</style>
