<script lang="ts">
	import { t } from "../../../lib/locale";
	import { Layout, setLayout } from "../../../layout/layout-context/layout-context";
	import OrderOverviewWidget from "../OrderOverviewWidget.svelte";
	import OrderUserSelector from "../OrderUserSelector.svelte";
	import { ActiveVps, User } from "../../../store";
	import Api from "../../../Api";
	import { type Vps, type VpsTemplate } from "../../../lib/vps/vps";
	import Loading from "../../../layout/Loading.svelte";
	import OrderSoftwareSelect from "../OrderSoftwareSelect.svelte";
	import OrderPayment from "../OrderPayment.svelte";
	import { formatBytes } from "../../../formater";
	import { debounce } from "../../../utils/debounce";
	import OrderResourceConfigurator from "../OrderResourceConfigurator.svelte";
	import OrderVpsProductWidget from "./OrderVpsProductWidget.svelte";
	import Warning from "../../../lib/Warning.svelte";
	import BootupScreen from "../../../lib/BootupScreen.svelte";
	import { preloadVideo } from "../../../utils/preload-video";
	import { DummyJob } from "../../../lib/jobs/DummyJob";
	import { JobManager } from "../../../lib/jobs/JobManager";
	import { ClientJobState } from "../../../types/ClientJob.type";
	import { delay } from "../../../utils/delay";
	import { error, success } from "../../../toaster";
	import { navigate } from "svelte-routing";

	export let layout: Layout = Layout.ANONYMOUS;
	setLayout(layout);

	document.title = `${$t('order.title')} - Helkor.eu`;

	type OrderData = {
		title: string;
		template: number;
		user_id: number | undefined;
		cpu: number;
		ram: number;
		disk: number;
		payment_cycle: 'monthly';
		root_password: string;
	}

	let viewRootPassword = false;
	let orderData: OrderData = {
		title: '',
		template: -1,
		user_id: $User?.id ?? undefined,
		cpu: 2,
		ram: 2048,
		disk: 10,
		payment_cycle: 'monthly',
		root_password: '',
	}

	function saveFormFields(order: typeof orderData) {
		sessionStorage.setItem('vpsOrderData', JSON.stringify({
			order,
		}));
	}
	function loadFormFiels() {
		const data = sessionStorage.getItem('vpsOrderData');
		if (!data) return;

		try {
			const savedData = JSON.parse(data);

			orderData = {
				...orderData,
				...savedData.order,
			}
		} finally {
			return;
		}
	}
	loadFormFiels();
	$: saveFormFields(orderData);

	function loadDataFromQuery() {
		const url = new URL(window.location.href);
		const searchParams = url.searchParams;

		const cpu = searchParams.get('cpu');
		if (cpu) {
			orderData.cpu = parseInt(cpu) ?? orderData.cpu;
		}
		const ram = searchParams.get('ram');
		if (ram) {
			orderData.ram = parseInt(ram) ?? orderData.ram;
		}
		const disk = searchParams.get('disk');
		if (disk) {
			orderData.disk = parseInt(disk) ?? orderData.disk;
		}

		if (cpu || ram || disk) {
			navigate('/order/vps', { replace: true });
		}
	}
	loadDataFromQuery();

	let vpsTemplateList: VpsTemplate[] = [];
	async function loadTemplates() {
		const response = await Api.get<VpsTemplate[]>('vps-templates');
		if (!response.success) {
			return;
		}
		vpsTemplateList = response.data;
	}
	loadTemplates();

	let price: number = 0;
	let available: boolean = false;
	let checkingPrice = true;

	type AvailabilityResponse = {
		price: number;
		availability: boolean;
	}
	const updatePrice = async () => {
		const response = await Api.get<AvailabilityResponse>('vps-calc-price', {
			cpu: orderData.cpu,
			ram: orderData.ram,
			disk: orderData.disk,
		});
		checkingPrice = false;
		if (!response.success) {
			price = 0;
			available = false;
			return;
		}

		price = response.data.price;
		available = response.data.availability;
	}
	updatePrice();
	const updatePriceDebounced = debounce(updatePrice, 500);
	const invalidatePriceAndUpdatePriceDebounced = () => {
		checkingPrice = true;
		updatePriceDebounced();
	}

	function validateFormAndReturnError(_dependencies: any[] = []) {
		if (checkingPrice) return $t('order.error.loading');
		if (!available) return $t('order.error.unavailable');
		if (orderData.title.length < 3) return $t('order.error.name.tooShort');
		if (orderData.title.length > 25) return $t('order.error.name.tooLong');
		if (orderData.template === -1) return $t('order.error.noTemplate');
		if (orderData.root_password.length < 8) return $t('order.error.rootPassword.tooShort');
		if (orderData.root_password.length > 25) return $t('order.error.rootPassword.tooLong');
		if (!orderData.user_id) return $t('order.error.needLogin');
		if (!$User || $User.credits < price) return $t('order.error.notEnoughCredits');
		return undefined;
	}
	let formError: string | undefined = $t('order.error.notValidated');
	$: formError = validateFormAndReturnError([
		available,
		checkingPrice,
		orderData.title,
		orderData.user_id,
		orderData.template,
		orderData.root_password,
		$User?.id,
	]);

	let submited = false;
	async function submitOrder() {
		if (validateFormAndReturnError() !== undefined) return;
		if (submited) return;

		submited = true;

		const dummyJob = new DummyJob();
		dummyJob.title = $t('order.job.title');
		dummyJob.message = $t('order.job.message');
		dummyJob.cancelable = false;
		JobManager.runJobPromise(dummyJob);

		try {
			const response = await Api.post<Vps>('vps', orderData);

			if (response.success) {
				await delay(3000);
				await User.fetch();
				await ActiveVps.fetch(response.data.id);
				dummyJob.state = ClientJobState.FINISHED;
				success($t('order.job.success'));
				sessionStorage.removeItem('vpsOrderData');
				navigate(`/vps/${response.data.id}`);
				return;
			}
			dummyJob.state = ClientJobState.FAILED;
			error(response.message ?? $t('order.job.error'));
		} catch (e) {
			dummyJob.state = ClientJobState.FAILED;
		} finally {
			submited = false;
		}
	}

	let preloadedBuildVideo: string;
	(async function(){
		preloadedBuildVideo = await preloadVideo('/video/construction.mp4');
	})();
</script>

{#if submited}
	<BootupScreen videoSource={preloadedBuildVideo} title={$t('order.job.title')} />
{:else}
<h1>{$t('order.title')}</h1>

<div class="order">
	<div class="order-form">
		<OrderVpsProductWidget />

		<div class="box">
			<h2>{$t('order.field.vps.resources.title')}</h2>
			<p>{$t('order.field.vps.resources.description')}</p>

			<OrderResourceConfigurator
				bind:cpu={orderData.cpu}
				bind:ram={orderData.ram}
				bind:disk={orderData.disk}

				onchange={invalidatePriceAndUpdatePriceDebounced}

				cpuMin={1}
				cpuMax={8}
				ramMin={2*1024}
				ramMax={32*1024}
				diskMin={10}
				diskMax={300}
			/>

			{#if !available && !checkingPrice}
				<Warning>
					{$t('order.field.vps.resources.unavailable')}
				</Warning>
			{/if}
		</div>


		<div class="box">
			<h2>{$t('order.field.vps.name.title')}</h2>
			<p>{$t('order.field.vps.name.description')}</p>

			<div class="input-group">
				<div class="input-group-prepend">
					<i class="fa-solid fa-pen"></i>
				</div>
				<input
					type="text"
					placeholder={$t('order.field.vps.name.placeholder')}
					minlength={3}
					maxlength={25}
					required
					bind:value={orderData.title}
				/>
			</div>
			<div class="input-validate">
				<div
					class="length-counter"
				>
					{orderData.title.length} / 25
				</div>
			</div>
		</div>

		<div class="box">
			<OrderUserSelector bind:value={orderData.user_id} />
		</div>

		<div class="box">
			<OrderPayment min={price} userId={orderData.user_id} />
		</div>

		<div class="box">
			<h2>{$t('order.field.vps.template.title')}</h2>
			<p>{$t('order.field.vps.template.description')}</p>

			{#if vpsTemplateList.length === 0}
				<Loading />
			{:else}
				<OrderSoftwareSelect
					options={vpsTemplateList.map((template, i) => ({
						id: template.id,
						nest_id: -1,
						name: template.title,
						description: template.description,
						created_at: '',
						updated_at: '',
						disabled: false,
						feature_flags: 0,
						image: template.image,
						pivot: {
							order: i,
							recommended: template.recommended,
						}
					}))}
					bind:value={orderData.template}
				/>
			{/if}
		</div>

		<div class="box">
			<h2>{$t('order.field.vps.rootpass.title')}</h2>
			<p>{$t('order.field.vps.rootpass.description')}</p>

			<div class="input-group">
				<div class="input-group-prepend">
					<i class="fa-solid fa-key"></i>
				</div>
				{#if !viewRootPassword}
					<input
						type="password"
						placeholder={'••••••••'}
						minlength={8}
						maxlength={25}
						required
						bind:value={orderData.root_password}
					/>
					<button class="input-group-append" on:click={() => viewRootPassword = true}>
						<i class="fa-solid fa-eye"></i>
					</button>
				{:else}
					<input
						type="text"
						placeholder={'••••••••'}
						minlength={8}
						maxlength={25}
						required
						bind:value={orderData.root_password}
					/>
					<button class="input-group-append" on:click={() => viewRootPassword = false}>
						<i class="fa-solid fa-eye-slash"></i>
					</button>
				{/if}
			</div>
			<div class="input-validate">
				<div
					class="length-counter"
				>
					{orderData.root_password.length} / 25
				</div>
			</div>
		</div>

	</div>
	<div class="order-overview">
		<OrderOverviewWidget
			error={formError}
			submit={submitOrder}
			data={{
				groups: [
					{
						title: 'Měsíčně',
						items: [
							{
								title: 'VPS hosting',
								price: checkingPrice ? 'Načítání ...' : available ? `${price} Kč` : 'Nedostupné',
								priceNote: available && !checkingPrice ? $t('order.field.product.perMonth') : '',
								params: [
									{
										title: $t('order.field.product.cpu'),
										value: `${orderData.cpu}x`
									},
									{
										title: $t('order.field.product.memory'),
										value: formatBytes(orderData.ram * 1024 * 1024),
									},
									{
										title: $t('order.field.product.disk'),
										value: formatBytes(orderData.disk * 1024 * 1024 * 1024)
									},
									{
										title: $t('order.field.vps.name.title'),
										value: orderData.title.trim(),
										hidden: orderData.title.trim().length === 0,
									},
									{
										title: $t('order.field.os.title'),
										value: vpsTemplateList.find(template => template.id === orderData.template)?.title ?? '',
										hidden: !vpsTemplateList.some(template => template.id === orderData.template),
									},
								]
							},
						],
					}
				],
			}}
		/>
	</div>
</div>
{/if}

<style>
	.order {
		display: flex;
		gap: 1rem;
	}
	.order-form {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 1.5rem;
	}
	.order-overview {
		flex: 0 0 30%;
	}

	p {
		color: var(--var-color-text-light);
		margin: .5rem 0;
	}

	.box {
		border: none;
	}

	@media (max-width: 1600px) {
		.order {
			flex-direction: column;
		}
		.order-overview {
			flex: 1;
		}
		/* .order-button {
			display: none;
		} */
	}
</style>
