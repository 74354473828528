import { derived, get, writable } from "svelte/store";
import { f } from '../utils/formatString';
import { localDeviceSettings } from "./local-device-settings";

const debugStrings = false;

export enum Language {
	CZECH = 'cs',
	ENGLISH = 'en',
}
const DEFAULT_LOCALE = Language.CZECH;

const dictionary = writable<any>({});
export const IsLocaleLoaded = writable(false);

export const t = derived(dictionary, $dictionary => {
	return (key: string, params: Record<string, string | number> | undefined = undefined) => {
		if (params !== undefined) {
			return translateWithFormater(key, params, $dictionary);
		}
		return translate(key, $dictionary);
	}
});
export function translate(key: string, dictionary: any) {


	const translation = dictionary[key];
	if (!translation) {
		return `{--${key}--}`;
	}

	if (debugStrings) {
		return `{${key}}`;
	}

	return translation;
}
export function translateWithFormater(key: string, params: Record<string, string | number>, dictionary: any) {
	const translation = translate(key, dictionary);
	return f(translation, params);
}

async function downloadLocalePack(lang: Language) {
	const url = `/lang/${lang}.json`;
	const response = await fetch(url);
	const json = await response.json();
	return json;
}

export async function initLocale(locale?: Language) {
	let activeLang = locale ?? get(localDeviceSettings).language ?? DEFAULT_LOCALE;
	if (!Object.values(Language).includes(activeLang as Language)) {
		activeLang = DEFAULT_LOCALE;
	}
	const newDictionary = await downloadLocalePack(activeLang as Language);
	dictionary.set(newDictionary);
	IsLocaleLoaded.set(true);
}
