import { derived, get, writable } from "svelte/store";
import Api from "./Api";
import { success } from "./toaster";
import ProfilePictureManager from "./lib/ProfilePictureManager";
import { Crisp } from "crisp-sdk-web";
import Cookies from "js-cookie";
import type { ServerFeatureFlags } from "./types/Enums";
import { hasFlag } from "./utils/flag-utils";
import { timeMonitor } from "./lib/time-monitor";
import type { DomainConfig } from "./lib/domain.types";
import { getDomainConfig } from "./lib/helkor-proxy";
import { type Vps, type VpsIdentifier, type VpsLiveUsage } from "./lib/vps/vps";

// User
export const UserLoading = writable(false);
export const User = function() {
	const { subscribe, set} = writable<User | undefined>(undefined);

	let gravatar = (user: User) => {
		user.avatar = ProfilePictureManager.getAvatar(user);
		return user;
	};

	let invalidation = Date.now();

	let fetch = async () => {
		const requestTime = Date.now();

		let token = Api.getToken();
		if(token === undefined) {
			set(undefined);
			return undefined;
		}

		UserLoading.set(true);
		let response = await Api.call('whoami',undefined,'GET');

		if (requestTime < invalidation) {
			UserLoading.set(false);
			return;
		}

		UserLoading.set(false);
		if (!response.success) {
			set(undefined);
			Crisp.session.setData({
				user_id: undefined,
				role: undefined,
			});
			return;
		}
		const user = gravatar(response.user);
		set(user);

		if (!localStorage.getItem('sudo_token')) {
			Crisp.setTokenId(response.user.id + '.' + response.user.identifier);
			Crisp.session.setData({
				url: `https://dash.helkor.eu/admin/users/edit/${response.user.id}`,
				role: response.user.role,
				credit: Math.round(response.user.credits),
				verified: response.user.email_verified ? 'Yes' : 'No',
			});
			Crisp.user.setAvatar(user.avatar!);
			Crisp.user.setNickname(response.user.username);
			Crisp.user.setEmail(response.user.email);
		}

	};

	if (Api.getToken() !== null)
	{
		fetch();
	}

	return {
		subscribe,
		set: (user: any) =>{
			set(gravatar(user));

		},
		fetch,
		logout: () => {
			invalidation = Date.now();
			if (localStorage.getItem('sudo_token'))
			{
				Api.call('auth/logout');
				localStorage.removeItem('sudo_token');
				fetch();
				success("SUDO relace ukončena");
			}
			else
			{
				Api.call('auth/logout');
				Cookies.remove('auth_token');
				set(undefined);
				success("Úspěšně odhlášen<br /><b>Měj se</b>", false);
				Crisp.session.pushEvent('signout');
			}
		}
	}
}();

// Active server
export const ActiveServer = function(){
	const activeServerStore = writable<Server | undefined>(undefined)
	const { subscribe, set, update } = activeServerStore;

	let oldId: ServerIdentifier | null | undefined;

	let forceFetch = async (id: ServerIdentifier) => {
		timeMonitor.start('fetch-active-server');
		try {
			const response = await Api.call('server/' + id + '/detail')
			if (!response.success) {
				set(undefined);
				return;
			}

			oldId = response.container.is_suspended ? undefined : id;
			set({
				meta: response.server,
				container: response.container
			});

			ActiveVps.set(undefined);

		} catch (_err: unknown) {
			set(undefined);
		} finally {
			timeMonitor.stop('fetch-active-server');
		}
	};

	return {
		subscribe,
		set: (x: any | undefined) => {
			oldId = x === undefined ? undefined : oldId;
			set(x);
		},

		forceFetch,
		fetch: async (id: ServerIdentifier) => {
			if (id === oldId) return;
			oldId = id;
			await forceFetch(id);
		},

		unsafeUpdate: update,

		hasFeature: (feature: ServerFeatureFlags) => {
			return hasFlag(get(activeServerStore)?.meta.feature_flags ?? 0, feature);
		}
	}
}();

const VPS_UPDATE_INTERVAL = 1000;
export const ActiveVps = function(){
	const activeVpsStore = writable<Vps | undefined>(undefined);
	let updateErrors = 0;

	const { subscribe, set, update } = activeVpsStore;

	let oldId: VpsIdentifier | null | undefined;
	let updateInterval: NodeJS.Timeout | undefined;
	let updateRunning = false;

	let resourceUpdater = async () => {
		if (updateRunning) return;
		updateRunning = true;
		const resources = await Api.get<VpsLiveUsage>(`/vps/${oldId}/resources`);
		updateRunning = false;
		if (!resources.success) {
			updateErrors++;
			if (updateErrors > 3) {
				clearInterval(updateInterval!);
			}
			return;
		}

		updateErrors = 0;
		update((x: Vps | undefined) => {
			if (x === undefined) return x;
			x.vm = resources.data;
			return x;
		});
	}

	let wrappedSet = (x: Vps | undefined) => {
		const isNew = x !== undefined && x.id !== oldId;
		oldId = x === undefined ? undefined : oldId;
		set(x);

		if ((isNew || x === undefined) && updateInterval !== undefined) {
			clearInterval(updateInterval);
			updateInterval = undefined;
		}

		if (x !== undefined && updateInterval === undefined) {
			updateInterval = setInterval(resourceUpdater,  VPS_UPDATE_INTERVAL);
		}
	};

	let forceFetch = async (id: VpsIdentifier) => {
		timeMonitor.start('fetch-active-vps');
		try {
			const response = await Api.get<Vps>(`/vps/${id}`);
			if (!response.data) {
				set(undefined);
				return;
			}

			ActiveServer.set(undefined);
			oldId = response.data.id;
			wrappedSet(response.data);
		} catch (_err: unknown) {
			wrappedSet(undefined);
		} finally {
			timeMonitor.stop('fetch-active-vps');
		}
	}

	return {
		subscribe,
		set: wrappedSet,
		forceFetch,
		fetch: async (id: VpsIdentifier) => {
			if (id === oldId) return;
			oldId = id;
			await forceFetch(id);
		},

		unsafeUpdate: update,
	};
}();

export const ActiveDomain = function(){
	const activeDomainStore = writable<DomainConfig | undefined>(undefined);
	const { subscribe, set, update } = activeDomainStore;

	let oldId: string | null | undefined;

	let forceFetch = async (id: string) => {
		timeMonitor.start('fetch-active-domain');
		try {
			const response = await getDomainConfig(id);
			if (!response.data) {
				set(undefined);
				return;
			}

			oldId = response.data.id;
			set(response.data);

		} catch (_err: unknown) {
			set(undefined);
		} finally {
			timeMonitor.stop('fetch-active-domain');
		}
	}

	return {
		subscribe,
		set: (x: any | undefined) => {
			oldId = x === undefined ? undefined : oldId;
			set(x);
		},

		forceFetch,
		fetch: async (id: string) => {
			if (id === oldId) return;
			oldId = id;
			await forceFetch(id);
		},
		unsafeUpdate: update,
	}
}();
