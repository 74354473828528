<script lang="ts">
	// import HelpBox from "../layout/HelpBox.svelte";
	import Api from '../../Api';
	import ServerStatWidget from "../../layout/ServerStatWidget.svelte";
	import { error} from '../../toaster';
	import VerifyEmailWarning from '../../layout/VerifyEmailWarning.svelte';
	import InfoboardWidget from './InfoboardWidget.svelte';
	import { Layout, setLayout } from '../../layout/layout-context/layout-context';
	import ChangelogWidget from './ChangelogWidget.svelte';
	import RandomTip from './RandomTip.svelte';
	import { t } from '../../lib/locale';

	let statsLoading = false;
	let stats = {
		servers: undefined as number | undefined,
		credits: undefined as number | undefined,
		credit_usage: undefined as number | undefined,
		credit_days: undefined as number | undefined,
	};
	function upadteStats()
	{
		statsLoading = true;
		Api.call('user/me/stats', {}, "POST", 600)
		.then(response=>{
			if (response.success) {
				stats = response.stats;
			} else {
				error(response.message ?? 'Nepodařilo se načíst statistiky');
			}
		}).catch(_=>{
			error('Chyba při načítání statistik');
		})
		.finally(()=>{
			statsLoading = false;
		});
	}
	upadteStats();

	document.title = 'Přehled - Helkor';
	setLayout(Layout.MAIN);
</script>


<h2>{$t('user.stats')}</h2>
<div class="stats">
	<ServerStatWidget box title={$t('user.stat.servers')} value={stats.servers?.toString() ?? '-'} />
	<ServerStatWidget box title={$t('user.stat.credit')} value={stats.credits?.toString() ?? '-'} />
	<ServerStatWidget box title={$t('user.stat.credit.usage')} value={stats.credit_usage?.toString() ?? '-'} max="měsíc" />
	<ServerStatWidget box title={$t('user.stat.credit.days')} value={(stats.credit_days ?? '-') + ' dní'} titleColor={(stats.credit_days ?? 10) <= 7 ? '#f44336' : undefined} />
</div>

<VerifyEmailWarning />

<div class="rows">
	<div class="col">
		<h2>{$t('infoboard')}</h2>
		<div class="box">
			<InfoboardWidget />
		</div>
		<RandomTip />
	</div>
	<div class="col">
		<h2>{$t('infoboard.changelog')}</h2>
		<div class="box">
			<ChangelogWidget />
		</div>
	</div>
</div>

<style>
	.rows {
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;
	}
	.col {
		flex: 1;
	}

	.col:first-of-type {
		margin-right: 0.5rem;
	}
	.col:last-of-type {
		margin-left: 0.5rem;
	}

	@media all and (max-width: 1200px)
	{
		.rows
		{
			display: block;
			flex-wrap: initial;
		}
		.col
		{
			flex: initial;
			margin: 0 !important;
		}
	}

	.stats {
		display: flex;
		justify-content: space-between;
		gap: .5rem;
		flex-wrap: wrap;
	}
</style>
