import Cookies from 'js-cookie';
import { writable } from 'svelte/store';

export const ApiActiveTransferCount = writable(0);

const apiCache: Record<string, any> = {};
const environments: Record<string, ApiEnvironment> = {
	local: {
		name: 'Localhost',
		origin: 'localhost',
		apiURL: 'http://localhost:5500',
		pteroURL: 'https://panel.helkor.eu/',
		proxyServerURL: 'http://proxy.localhost:3000/',
		authDomain: 'localhost',
		debug: true,
	},
	staging: {
		name: 'Staging',
		origin: 'dash.staging.helkor.eu',
		apiURL: 'https://api.staging.helkor.eu',
		pteroURL: 'https://panel.helkor.eu/',
		proxyServerURL: 'https://proxy.helkor.eu/',
		authDomain: 'staging.helkor.eu',
		debug: true,
	},
	production: {
		name: 'Production',
		origin: 'dash.helkor.eu',
		apiURL: 'https://api.helkor.eu',
		pteroURL: 'https://panel.helkor.eu/',
		proxyServerURL: 'https://proxy.helkor.eu/',
		authDomain: 'helkor.eu',
		debug: false,
	}
} as const;

type ApiResponse<T> = {
	success: boolean;
	message?: string;
	data: T;
};

export default class Api {
	static get environment(): ApiEnvironment {
		const env = Object.entries(environments)
			.map(([key, value], index) => value)
			.find(env => location.origin.includes(env.origin)) ?? {
				name: 'Unknown',
				origin: 'dash.helkor.eu',
				apiURL: 'https://api.helkor.eu',
				pteroURL: 'https://panel.helkor.eu/',
				proxyServerURL: 'https://proxy.helkor.eu/',
				debug: true,
				authDomain: 'helkor.eu',
			};

		if (env.apiURL.endsWith('/')) {
			env.apiURL = env.apiURL.substring(0, env.apiURL.length - 1);
		}

		return env;
	}

	static _baseURL = Api.environment.apiURL;
	static _pteroURL = Api.environment.pteroURL;

	static getToken(): string | null {

		const sudoToken = localStorage.getItem('sudo_token');
		if (sudoToken) {
			return sudoToken;
		}
		const authToken = Cookies.get('auth_token');
		if (!authToken) {
			return null;
		}

		this.setToken(authToken);
		return authToken;
	}

	static setToken(token: string) {
		Cookies.set('auth_token', token, {
			expires: 14,
			sameSite: 'strict',
			secure: true,
			path: '/',
			domain: Api.environment.authDomain,
		});
	}

	static getTokenHash(): string {
		const token = this.getToken();
		if (token === null || token === undefined) {
			return 'anonym';
		}
		return token.substring(0,10);
	}

	static isProduction(): boolean {
		return !this.environment.debug;
	}

	/**
	 * @param {String} endpoint Server remote function to call
	 * @param {*} data Request payload data
	 * @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'|'COPY'|'HEAD'|'OPTIONS'} method Request method
	 * @returns Response data
	 */
	static async call(endpoint: string, data: any = undefined, method = 'POST', cache = 0) {

		if (endpoint.startsWith('/')) {
			endpoint = endpoint.substring(1);
		}


		const requestBody = JSON.stringify(data);
		const cacheKey = `${method}://${this.getTokenHash()}@${endpoint}+${requestBody}`;

		if (cache > 0 && apiCache[cacheKey] != undefined) {
			if (apiCache[cacheKey].timestamp + cache * 1000 > new Date().getTime()) {
				return apiCache[cacheKey].response;
			}
		}

		let response = null;
		try
		{
			ApiActiveTransferCount.update(n => n + 1);
			let request = await fetch(this._baseURL + '/' + endpoint,{
				method: method,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': this.getToken() ?? "",
				},
				body: requestBody,
			});

			response = await request.json();
			response.code = request.status;
		}
		catch(e)
		{
			response = {
				success: false
			};
		}
		finally
		{
			ApiActiveTransferCount.update(n => n - 1);
		}

		if (cache > 0 && response.success) {
			apiCache[cacheKey] = {
				timestamp: new Date().getTime(),
				response: response,
			}
		}

		return response;
	}

	static async get<T>(endpoint: string, queryParams: Record<string, string | number> = {}, cache = 0): Promise<ApiResponse<T>> {
		const query = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
		return this.call(`${endpoint}${query.length ? '?' : ''}${query}`, undefined, 'GET', cache);
	}

	static async post<T>(endpoint: string, data: any = undefined, cache = 0): Promise<ApiResponse<T>> {
		return this.call(endpoint, data, 'POST', cache);
	}

	static async delete<T>(endpoint: string, data: any = undefined, cache = 0): Promise<ApiResponse<T>> {
		return this.call(endpoint, data, 'DELETE', cache);
	}
}
