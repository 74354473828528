<script lang="ts">
	import { clickOutside } from '../clickOutside';
	import { removeDiactrics } from '../formater';
	import Api from '../Api';
	import Loading from '../layout/Loading.svelte';
	import ProfilePictureManager from '../lib/ProfilePictureManager';

	export let value: number | undefined = undefined;

	let isOpen = false;
	let loading = true;
	let users: Record<number, User> = {};
	let searchElement: HTMLInputElement | undefined;
	let search = '';

	function open() {
		isOpen = true;
		search = '';
		setTimeout(() => {
			searchElement?.focus();
		}, 0);
	}
	function close() {
		isOpen = false;
	}

	async function updateUsers() {
		loading = true;
		try {
			const response = await Api.call('users');
			if (response.success) {
				const userList: typeof users = {};
				response.users.forEach((user: User) => {
					userList[user.id] = user;
				});
				users = userList;
			} else {
				users = [];
			}
		} finally {
			loading = false;
		}
	}
	updateUsers();

	const setValue = (user: User) => () => {
		isOpen = false;
		value = user.id;
	}

	const searchResultFilter = (search: string) => (user: User) =>{
		if (search.trim().length === 0) {
			return true;
		}

		const searchParts = removeDiactrics(search.trim().toLowerCase()).split(' ');
		const normalizedUsername = removeDiactrics(user.username.toLowerCase());
		const normalizedEmail = removeDiactrics(user.email.toLowerCase());
		const normalizedId = user.id.toString().toLowerCase();

		return searchParts.every(part => normalizedUsername.includes(part) || normalizedEmail.includes(part) || normalizedId.includes(part));
	}

</script>

<svelte:window on:keydown={e => {
	if (e.key === 'Escape') {
		e.preventDefault();
		close();
	}

	if (e.key === 'Enter') {
		const searchResults = Object.values(users).filter(searchResultFilter(search));
		if (searchResults.length > 0) {
			e.preventDefault();
			setValue(searchResults[0])();
		}
	}
}} />
<div
	class="wrap"
	class:active={isOpen}
	use:clickOutside={close}
>
	<button class="list-selected" on:click={open}>
		{#if loading}
			Načítání uživatelů...
		{:else if !value}
			Vyberte uživatele
		{:else if users[value]}
			<button class="option" on:click={setValue(users[value])}>
				<div class="profile-picture">
					<img src={ProfilePictureManager.getAvatar(users[value])} alt="User Profile" loading="lazy" />
				</div>
				<div class="user-info">
					<div class="user-info__name">{users[value].username}</div>
					<div class="user-info__mail">{users[value].email}</div>
				</div>
				<div class="user-id">
					{`#${users[value].id.toString().padStart(4, '0')}`}
				</div>
			</button>
		{:else}
			Chyba - Vybrán neexistující uživatel
		{/if}
	</button>
	<div class="list-available">
		<input
			class="search-bar"
			type="text"
			placeholder="Hledat uživatele"
			bind:this={searchElement}
			bind:value={search}
		/>

		{#if loading}
			<Loading />
		{:else}
			{@const resultUsers = Object.values(users).filter(searchResultFilter(search))}
			<div class="scrollable">
				{#each resultUsers as user (user.id)}
					<button class="option" on:click={setValue(user)}>
						<div class="profile-picture">
							<img src={ProfilePictureManager.getAvatar(user)} alt="User Profile" loading="lazy" />
						</div>
						<div class="user-info">
							<div class="user-info__name">{user.username}</div>
							<div class="user-info__mail">{user.email}</div>
						</div>
						<div class="user-id">
							{`#${user.id.toString().padStart(4, '0')}`}
						</div>
					</button>
				{/each}
				{#if resultUsers.length === 0}
					<div class="no-results">
						Žádné výsledky neodpovídají vašemu hledání
					</div>
				{/if}
			</div>
		{/if}
	</div>
</div>

<style>
	.wrap {
		position: relative;
		margin: 0.4rem 0;
	}
	.list-selected {
		font-family: 'Oxanium', 'Noto Sans', sans-serif;
		width: 100%;
		background-color: var(--var-color-dark);
		background-image: url(/img/caret-down-solid.svg);
		background-size: 12px;
		background-repeat: no-repeat;
		background-position: calc(100% - 8px) center;
		border: 1px solid var(--var-color-border);
		box-sizing: border-box;
		border-radius: 5px;
		padding: 0.5rem 0.7rem;
		font-size: 0.9rem;
		color: white;
		outline: none;
		cursor: text;
	}
	.wrap.active .list-selected {
		border-radius: 5px 5px 0 0;
	}

	.list-available {
		display: none;
		background: var(--var-color-background-dark);
		position: absolute;
		width: 100%;
		border: 1px solid var(--var-color-border);
		border-top: none;
		box-sizing: border-box;
		border-radius: 0 0 5px 5px;
		z-index: 3;
	}
	.scrollable {
		max-height: 20rem;
		overflow-y: auto;
	}

	.wrap.active .list-available {
		display: block;
	}

	.option {
		padding: 0.5rem 0.7rem;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
		width: 100%;
		background-color: transparent;
		border: none;
		text-align: left;
	}

	.option:hover {
		background: var(--var-color-background);
	}

	.profile-picture img {
		width: 2.5rem;
		height: 2.5rem;
		object-fit: cover;
		border-radius: 5px;
	}

	.user-id {
		color: var(--var-color-text-light);
		font-size: 0.8rem;
		margin-left: auto;
	}

	.list-selected .option {
		padding: 0;
	}
	.list-selected .option:hover {
		background: none;
	}
	.list-selected .user-id {
		margin-right: 1.25rem;
	}

	.search-bar {
		margin: 0;
		border: none;
		border-radius: 0;
		padding: 0.75rem 1rem;
		border-bottom: 1px solid var(--var-color-border);
		background: transparent;
	}

	.no-results {
		color: var(--var-color-text-light);
		text-align: center;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.user-info__name {
		font-size: 1.1rem;
		font-weight: 600;
	}

	.user-info__mail {
		font-size: 0.9rem;
		color: var(--var-color-text-light);
	}
</style>
