<script lang="ts">
	import { formatPlural } from "../../formater";
	import Chip from "../../layout/Chip.svelte";
	import SkeletonBlock from "../../layout/skeleton/SkeletonBlock.svelte";
	import SkeletonText from "../../layout/skeleton/SkeletonText.svelte";
	import { t } from "../../lib/locale";
	import { ServerFeatureFlags } from "../../types/Enums";
	import { hasFlag } from "../../utils/flag-utils";

	export let product: Product | undefined;

	let alsoIncluded: [boolean, string][] = [];
	$: {
		if (product) {
			alsoIncluded = [
				[hasFlag(product.feature_flags, ServerFeatureFlags.MARKETPLACE), $t('order.feature.marketplace')],
				[!!product.databases, $t('order.feature.database', {
					count: product.databases
				})],
				[!!product.backups, $t('order.feature.backup.template', {
					count: product.backups,
					backup: formatPlural(
						product.backups,
						$t('order.feature.backup.one'),
						$t('order.feature.backup.few'),
						$t('order.feature.backup.many'),
					),
				})],
				[!!product.allocations, $t('order.feature.allocations.template', {
					count: product.allocations,
					allocation: formatPlural(
						product.allocations,
						$t('order.feature.allocations.one'),
						$t('order.feature.allocations.few'),
						$t('order.feature.allocations.many'),
					)
				})],
				[true, $t('order.feature.antiDDoS')],
			];
		}
	}

	function getTagsFromText(text: string) {
		const lines = text.split('\n');
		const tags: {tag: string, color?: string}[] = [];
		while (lines[lines.length - 1].trim() === '') {
			lines.pop();
		}
		while (lines[lines.length - 1].startsWith('#')) {
			const tagLine = lines.pop()!;
			const fullTag = tagLine.slice(1).trim();
			if (fullTag.startsWith('[')) {
				const [color, tag] = fullTag.slice(1).split(']');
				tags.push({ tag, color });
			} else {
				tags.push({ tag: fullTag });
			}
		}
		return tags;
	}

	function filterTagsFromText(text: string) {
		const lines = text.split('\n');
		while (lines[lines.length - 1].trim() === '') {
			lines.pop();
		}
		while (lines[lines.length - 1].startsWith('#')) {
			lines.pop();
		}
		return lines.join('\n');
	}

	function tagColorMap(color: string | undefined) {
		switch (color) {
			case 'green':
			case 'success':
			case 'lime':
				return 'success';
			case 'red':
			case 'danger':
				return 'danger';
			default:
				return 'default';
		}
	}

</script>

<div class="product_widget">
	<div
		class="product_widget__header"
	>
		<div class="info">
			{#if product}
				<img
					class="info__image"
					src={product.image}
					alt={product.name + " | Helkor"}
					draggable="false"
				/>
			{:else}
				<div class="info__image">
					<SkeletonBlock />
				</div>
			{/if}
			<span class="info__name">
				{#if product}
					{product.name}
				{:else}
					<SkeletonText width={8} />
				{/if}
			</span>
			<div class="info__chips">
				<!-- <Chip type='success'>Doporučujeme</Chip> -->
				<!-- <Chip type="danger">Nejvýhodnější</Chip> -->
			</div>
		</div>

		{#if product}
			<table>
				<tbody>
					<!-- vCPU -->
					<tr>
						<td>
							<b>{$t('order.field.product.cpu')}</b>
						</td>
						<td>
							{product.cpu / 100}x
						</td>
					</tr>
					<!-- RAM -->
					<tr>
						<td>
							<b>{$t('order.field.product.memory')}</b>
						</td>
						<td>
							{product.memory / 1024} GB
						</td>
					</tr>
					<!-- Úložiště -->
					<tr>
						<td>
							<b>{$t('order.field.product.disk')}</b>
						</td>
						<td>
							{product.disk / 1000} GB
						</td>
					</tr>
					<!-- Cena -->
					<tr>
						<td>
							<b>{$t('order.field.product.price')}</b>
						</td>
						<td>
							{product.price} Kč / {$t('order.field.product.month')}
						</td>
					</tr>
				</tbody>
			</table>
		{:else}
			<div class="property"><SkeletonText width={3} /></div>
			<div class="property"><SkeletonText width={3} /></div>
			<div class="property"><SkeletonText width={3} /></div>
			<div class="property"><SkeletonText width={3} /></div>
		{/if}
	</div>
	<div class="product_widget__content">
		<div class="description">
			{#if product}
				{#if product.description}
					<b>{$t('order.field.product.descriptionTitle')}</b><br />
					{#each filterTagsFromText(product.description).split('\n') as line, i}
						<div class="description__line" class:description__line--first={i === 0}>{line}</div>
					{/each}
				{/if}
			{:else}
				<SkeletonText multiline />
				<SkeletonText multiline />
				<SkeletonText multiline />
				<SkeletonText width={"two-thirds"} />
			{/if}
		</div>
		<div>
			<div class="features">
				{#if alsoIncluded.some(([included]) => included)}
					<b>{$t('order.field.product.featuresTitle')}</b>
					{#each alsoIncluded as [included, text]}
						{#if included}
							<div class="features__item">{text}</div>
						{/if}
					{/each}
				{/if}
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	.product_widget {
		&__header {
			padding: 1.5rem;
			background-color: #151617;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			gap: .25rem;
			border-radius: 5px 5px 0px 0px;


			.info {
				flex: 1 1 auto;
				display: flex;
				align-items: center;
				gap: 1.5rem;
				width: 100%;
				margin-bottom: 1rem;

				&__image {
					width: 2.5rem;
					height: 2.5rem;
					object-fit: cover;
				}

				&__name {
					font-weight: 600;
					font-size: 1.2rem;
					color: white;
					text-transform: uppercase;
				}

				&__chips {
					display: flex;
					gap: .75rem;
				}
			}

			.show-more-button img {
				transform: rotate(0deg);
			}
		}

		&__content {
			background-color: #151617;
			border-radius: 0px 0px 5px 5px;
			display: flex;
			flex-direction: column;
			gap: 2rem;
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			padding: 0 1.5rem;

			transition: all 0.2s ease-in-out;

			padding: 1rem 1.5rem 1.5rem 1.5rem;
			max-height: 100vh;
			opacity: 1;
		}
	}

	.features {
		display: flex;
		flex-direction: column;
		gap: .25rem;

		&__item {
			color: var(--var-color-text-light);
		}

		&__item::before {
			content: " ";
			height: 1.75em;
			width: .75em;
			display: inline-block;
			font-size: .5rem;
			border-bottom: 3px solid #388e3c;
			border-right: 3px solid #388e3c;
			transform: rotate(45deg);
			margin-right: .75rem;
		}
	}

	.description__line {
		font-size: 1rem;
		// margin-bottom: .5rem;
		color: var(--var-color-text-light);
		font-weight: 300;


		&--first {
			margin-top: .5rem;
		}
	}
</style>
