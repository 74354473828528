<script lang="ts">
	import { formatBytes } from "../../formater";
	import OrderResourceConfiguratorResource from "./OrderResourceConfiguratorResource.svelte";

	export let cpu: number;
	export let ram: number;
	export let disk: number;

	export let cpuMin: number = 1;
	export let cpuMax: number = 10;
	export let ramMin: number = 512;
	export let ramMax: number = 32*1024;
	export let diskMin: number = 1;
	export let diskMax: number = 100;

	export let onchange: undefined | (() => void) = undefined;

	function handleChange() {
		if (onchange) {
			onchange();
		}
	}
</script>

<div class="resource-configurator">
	<OrderResourceConfiguratorResource
		bind:value={cpu}
		onchange={handleChange}
		min={cpuMin}
		max={cpuMax}
		step={1}
		title="CPU"
		minLabel={`${cpuMin} x`}
		maxLabel={`${cpuMax} x`}
		unit="x"
	/>
	<OrderResourceConfiguratorResource
		bind:value={ram}
		onchange={handleChange}
		min={ramMin}
		max={ramMax}
		step={1024}
		title="RAM"
		minLabel={formatBytes(ramMin*1024*1024)}
		maxLabel={formatBytes(ramMax*1024*1024)}
		unit="MB"
	/>
	<OrderResourceConfiguratorResource
		bind:value={disk}
		onchange={handleChange}
		min={diskMin}
		max={diskMax}
		step={5}
		title="Disk"
		minLabel={`${diskMin} GB`}
		maxLabel={`${diskMax} GB`}
		unit="GB"
	/>
</div>

<style>
	.resource-configurator {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		margin: 1rem 0;
	}
</style>
