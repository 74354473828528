<script lang="ts">
	import { Link } from "svelte-routing";
	import { localDeviceSettings } from "../lib/local-device-settings";
	import { ActiveVps } from "../store";
	import NavHeader from "./NavHeader.svelte";
	import NavItem from "./NavItem.svelte";
	import { t } from "../lib/locale";

	let hidden = true;

	const updateActiveVps = () => {
		if ($ActiveVps && !location.pathname.includes('/vps/') && !$localDeviceSettings.pinServer) {
			hidden = true;
			setTimeout(() => {
				ActiveVps.set(undefined);
			}, 250);
		}
		return {
			style: "display:none;"
		};
	}

	async function toggleServerPin() {
		localDeviceSettings.update(settings => {
			settings.pinServer = !settings.pinServer;
			if (!settings.pinServer) {
				updateActiveVps();
			}
			return settings;
		});
	}

	$: {
		if ($ActiveVps) {
			hidden = false;
		}
	}
</script>

<Link to='/vps' getProps={updateActiveVps}>Location Detection</Link>

{#if $ActiveVps}
<div class="menu-show-animation" class:hiding={hidden} >
	<NavHeader text={`${($ActiveVps?.title ?? '')}`}>
		<button class="pin-icon" class:active={$localDeviceSettings.pinServer} on:click={toggleServerPin} title={$t('menu.pin')}>
			<i class="fa-solid fa-thumbtack"></i>
		</button>
	</NavHeader>

	<NavItem icon="fa-solid fa-plug" text={$t('menu.vps.power')} href="/vps/{$ActiveVps.id}" exact />
	<NavItem icon="fa-solid fa-display" text={$t('menu.vps.vnc')} href="/vps/{$ActiveVps.id}/vnc" />
	<NavItem icon="fa-solid fa-key" text={$t('menu.vps.security')} href="/vps/{$ActiveVps.id}/security" />
	<NavItem icon="fa-solid fa-network-wired" text={$t('menu.vps.network')} href="/vps/{$ActiveVps.id}/network" />
	<NavItem icon="fa-solid fa-compact-disc" text={$t('menu.vps.reinstall')} href="/vps/{$ActiveVps.id}/reinstall" />
	<NavItem icon="fa-solid fa-receipt" text={$t('menu.vps.billing')} href="/vps/{$ActiveVps.id}/billing" />
</div>
{/if}

<style lang="scss">
	.pin-icon {
		float: right;
		transform: translateX(100%) rotate(45deg);
		opacity: .3;
		cursor: pointer;
		transition: all .1s ease-in-out;
		background-color: transparent;
		border: none;
		padding: 0;
	}
	.pin-icon:hover {
		opacity: .8;
	}
	.pin-icon.active {
		opacity: 1;
		transform: translateX(100%) rotate(0deg);
	}

	.menu-show-animation {
		animation: menu-show .3s ease-in-out;
	}
	.menu-show-animation.hiding {
		animation: menu-hide .3s ease-in-out;
	}

	@keyframes menu-show {
		0% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
		100% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
	}
	@keyframes menu-hide {
		0% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
		100% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
	}
</style>
