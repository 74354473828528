<script>
	import { setContext } from "svelte";
	import { createEffectProxy, shakeEffect } from "../../../effects";
	import InternetConnectionStatus from "../../InternetConnectionStatus.svelte";
	import { t } from "../../../lib/locale";

	const shake = createEffectProxy(shakeEffect);
	setContext('shake', shake);
</script>

<div class="wrap">
	<div>
		<div class="shape"></div>
		<div class="shape"></div>
	</div>
	<div class="login-box" use:shake>
		<div class="brand">
			<img src="https://helkor.eu/img/logo_white.svg" alt="Helkor logo" />
			<InternetConnectionStatus />
		</div>
		<div class="headline">
			<img src="https://helkor.eu/img/favicon.svg" alt="Helkor icon small" />
			<span class="title">{$t('login.action.login')}</span>
			<span class="brand"><a href="https://helkor.eu">Helkor.eu</a></span>
		</div>
		<slot />
	</div>
</div>

<style>
	.wrap {
		margin: 50vh auto 1rem auto;
		transform: translateY(-50%);
		width: 500px;
		max-width: 100%;
	}

	.login-box {
		box-shadow: 1px 1px 4px #06060669, -1px -1px 4px #292a2c99;
		background-color: #15161859;
		backdrop-filter: blur(10px);
		width: 100%;
		border-radius: 5px;
		color: white;
		padding: 0.5rem 1rem;
		box-sizing: border-box;

	}

	.brand img {
		width: 55%;
		height: auto;
		margin: 0.5rem auto;
		display: block;
		padding: 0.4rem 0 0.8rem 0;
	}

	.headline {

		display: none;

		padding: 0.4rem 0 0.8rem 0;
		color: var(--var-color-text-light);

		border-bottom: 1px solid var(--var-color-border);
	}

	.headline img {
		max-height: 1.5rem;
		vertical-align: middle;
		transform: translateY(-0.07rem);
	}

	.headline span {
		vertical-align: middle;
		font-size: 1.2rem;
		margin-left: 0.3rem;
		font-weight: 300;
	}
	.headline a {
		color: var(--var-color-text-light);
		text-decoration: none;
		float: right;
	}
	.headline a:hover {
		text-decoration: underline;
		color: var(--var-color-primary);
	}

	.shape{
		width: 0;
		height: 0;
		position: absolute;
		border-radius: 50%;
	}
	.shape:first-child{
		background: #40b3ff;
		box-shadow: 0 0 12rem 6rem #107ac1;
		left: 2rem;
		top: 2rem;
		animation: pulse 9s ease-in-out 0s infinite;
	}
	.shape:last-child{
		background: #dc2f28;
		right: 5rem;
    	bottom: 4rem;
		animation: pulse 9s ease-in-out 3s infinite;

		box-shadow: 0 0 11rem 6rem #dc2f28;
		opacity: 0.7;
	}

	@media screen and (max-width: 500px)
	{
		.wrap {
			margin: 5rem auto 1rem auto;
			transform: none;
		}
		.shape {
			display: none;
		}
		.login-box {
			box-shadow: none;
			width: 100%;
			border: none;
			background: none;
		}
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.1);
		}
		100% {
			transform: scale(1);
		}
	}
</style>
