<script lang="ts">
  import { setContext } from "svelte";
	import Header from "../../Header.svelte";
	import Sidebar from "../../Sidebar.svelte";
  import { Layout, wideLayout } from "../layout-context";

	export let hideHeader = false;
	let openSidebar = false;

	let showPadding = true;
</script>

{#if !hideHeader}
	<Header bind:openSidebar>
		<slot name="header" />
		<slot name="header-actions" slot="actions" />
		<slot name="header-actions-mobile" slot="actions-mobile" />
	</Header>
{/if}
<Sidebar bind:openSidebar>
	<slot name="sidebar" />
</Sidebar>

<div class="content-wrapper" class:no-padding={$wideLayout}>
	<slot />
</div>

<style>
	.content-wrapper {
		transition: margin-left .3s ease-in-out;
		margin-left: 250px;
		color: white;
		box-sizing: border-box;
		padding: 2rem 4rem;
	}
	.content-wrapper.no-padding {
		padding: 0;
	}

	@media all and (max-width: 1200px)
	{
		.content-wrapper {
			margin-left: 3.5rem;
			padding: 2rem 2rem;
		}
	}

	@media all and (max-width: 767px)
	{
		.content-wrapper {
			margin-left: 0;
			padding: 2rem 1rem;
		}
	}
</style>
