<script lang="ts">
	import { formatPlural } from "../../../formater";
	import Chip from "../../../layout/Chip.svelte";
	import SkeletonBlock from "../../../layout/skeleton/SkeletonBlock.svelte";
	import SkeletonText from "../../../layout/skeleton/SkeletonText.svelte";
	import { t } from "../../../lib/locale";

	let alsoIncluded: [boolean, string][] = [
		[true, $t('order.feature.ipv4')],
		[true, $t('order.feature.network.speed.5Gbps')],
		[true, $t('order.feature.vnc.browser')],
		[true, $t('order.feature.root')],
		[true, $t('order.feature.ssh')],
		[true, $t('order.feature.antiDDoS')],
	];
</script>

<div class="product_widget">
	<div class="product_widget__header">
		<div class="info">
			{#if false}
				<img
					class="info__image"
					src={''}
					alt={" | Helkor"}
					draggable="false"
				/>
			{:else}
				<div class="info__image">
					<SkeletonBlock />
				</div>
			{/if}
			<span class="info__name">
				VPS
			</span>
			<div class="info__chips">
				<!-- <Chip type='success'>Doporučujeme</Chip> -->
				<!-- <Chip type="danger">Nejvýhodnější</Chip> -->
			</div>
		</div>
	</div>
	<div class="product_widget__content">
		<div class="description">
				<b>{$t('order.field.vps.descriptionTitle')}</b><br />
				{#each $t('order.field.vps.descriptionTitle.contnent').split('\n') as line, i}
					<div class="description__line" class:description__line--first={i === 0}>{line}</div>
				{/each}
		</div>
		<div>
			<div class="features">
				{#if alsoIncluded.some(([included]) => included)}
					<b>{$t('order.field.product.featuresTitle')}</b>
					{#each alsoIncluded as [included, text]}
						{#if included}
							<div class="features__item">{text}</div>
						{/if}
					{/each}
				{/if}
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	.product_widget {
		&__header {
			padding: 1.5rem;
			background-color: #151617;
			border-radius: 5px;
			display: flex;
			align-items: center;
			gap: 1.5rem;
			border-radius: 5px 5px 0px 0px;

			.info {
				flex: 1 1 auto;
				display: flex;
				align-items: center;
				gap: 1.5rem;

				&__image {
					width: 2.5rem;
					height: 2.5rem;
					object-fit: cover;
				}

				&__name {
					font-weight: 600;
					font-size: 1.2rem;
					color: white;
					text-transform: uppercase;
				}

				&__chips {
					display: flex;
					gap: .75rem;
				}
			}


			.show-more-button img {
				transform: rotate(0deg);
			}
		}

		&__content {
			background-color: #151617;
			border-radius: 0px 0px 5px 5px;
			padding: 0 1.5rem;
			display: flex;
			max-height: 0;
			overflow: hidden;
			opacity: 0;
			gap: 2rem;

			transition: all 0.2s ease-in-out;

			padding: 1rem 1.5rem 1.5rem 1.5rem;
			max-height: 100vh;
			opacity: 1;

			& > div {
				flex: 1 1 40%;
				&:first-child {
					flex: 1 1 60%;
				}

			}
		}
	}

	.features {
		display: flex;
		flex-direction: column;
		gap: .25rem;

		&__item::before {
			content: " ";
			height: 1.75em;
			width: .75em;
			display: inline-block;
			font-size: .5rem;
			border-bottom: 3px solid #388e3c;
			border-right: 3px solid #388e3c;
			transform: rotate(45deg);
			margin-right: .75rem;
		}
	}

	.description__line {
		font-size: 1rem;
		// margin-bottom: .5rem;
		color: var(--var-color-text-light);
		font-weight: 300;


		&--first {
			margin-top: .5rem;
		}
	}
</style>
