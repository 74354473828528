export type MinecraftTemplate = {
	title: string;
	image: string;
	link?: string;
	env: Record<string, string>;
	javaVersion?: string;
};

export const MinecraftTemplates: Record<string, MinecraftTemplate> = {
	'template-modpack-better-mc-forge-1.20.1-v30.5-1725019282': {
		title: 'Better MC [FORGE] 1.20.1 v30.5',
		image: "https://media.forgecdn.net/avatars/thumbnails/835/282/256/256/638226792192343447.png",
		link: "https://www.curseforge.com/minecraft/modpacks/better-mc-forge-bmc4",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-better-mc-forge-1.20.1-v30.5-1725019282.tar.gz',
			SERVER_JARFILE: 'server.jar',
		},
		javaVersion: '17',
	},
	'template-modpack-majnr-plus-1722864777': {
		title: 'Majnr Plus v2',
		image: "https://media.forgecdn.net/avatars/thumbnails/922/756/256/256/638388583052574811.jpeg",
		link: "https://www.curseforge.com/minecraft/modpacks/majnr-plus",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-majnr-plus-1722864777.tar.gz',
			SERVER_JARFILE: 'fabric-server.jar',
		},
		javaVersion: '21',
	},
	'template-modpack-rlcraft-1.12.2-release-v2.9.3-1725111000': {
		title: 'RLCraft 1.12.2 - Release v2.9.3',
		image: "https://media.forgecdn.net/avatars/thumbnails/468/243/256/256/637751369169569212.png",
		link: "https://www.curseforge.com/minecraft/modpacks/rlcraft",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-rlcraft-1.12.2-release-v2.9.3-1725111000.tar.gz',
			SERVER_JARFILE: 'server.jar',
		},
		javaVersion: '8',
	},
	'template-modpack-rpg-techpack-czech-v1.0.2-1724056777': {
		title: 'RPG TechPack Czech v1.0.2',
		image: "https://media.forgecdn.net/avatars/thumbnails/1047/162/256/256/638575399507976123.jpg",
		link: "https://www.curseforge.com/minecraft/modpacks/rpg-techpack-czech",
		env: {
			MINECRAFT_JAR_ID: 'template',
			TEMPLATE_URL: 'https://storage.helkor.eu/system/installer/minecraft/modpacks/modpack-rpg-techpack-czech-v1.0.2-1724056777.tar.gz',
			SERVER_JARFILE: 'server.jar',
		},
		javaVersion: '21',
	},
}
