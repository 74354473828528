<script lang="ts">

</script>

<div class="warning">
	<div>
		!
	</div>
	<div>
		<slot />
	</div>
</div>

<style>
	.warning {
		align-items: flex-start;
		background: rgb(10 10 10 / .3);
		border-radius: .2rem .5rem .5rem .2rem;
		border-left: .2rem solid #ff9800;
		color: #ff9800;
		display: flex;
		gap: .5rem;
		padding: 0.6rem 1rem;
	}

	.warning :global(a) {
		color: #ffb952;
	}
</style>
