import { Cache } from './cache';

function keyHash<T>(obj: T): string {
	if (Array.isArray(obj)) {
		return obj.map(keyHash).join(',');
	}

	switch (typeof obj) {
		case 'string':
			return obj as string;
		case 'number':
			return obj.toString();
		case 'boolean':
			return obj.toString();
		default:
			return JSON.stringify(obj);
	}
}

export function cached<T extends (...args: Parameters<T>) => ReturnType<T>>(fn: T, ttlMiliseconds: number) {
	const cache = new Cache<ReturnType<T>>(ttlMiliseconds);

	return (...args: Parameters<T>): ReturnType<T> => {
		const key = keyHash(args);
		const cachedValue = cache.get(key);
		if (cachedValue !== undefined) {
			return cachedValue;
		}

		const value = fn(...args);
		cache.set(key, value);
		return value;
	};
}
