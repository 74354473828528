<script lang="ts">
// @ts-nocheck

	import { User } from "../store";
	import { link } from "svelte-routing";
	import { clickOutside } from "../clickOutside";
	import { t } from "../lib/locale";

	export let user: User | null = null;
	export let mobile = false;
	let opened = false;
	function open() {
		opened = !opened;
	}

</script>

<button class="nav-item" on:click={open} class:mobile>
	<span>{user.username}</span>
	<img src={user.avatar ?? "https://fakeimg.pl/64x64"} alt="" />

	{#if opened}
		<div class="dropdown" use:clickOutside on:click_outside={open}>
			<a class="dropdown-item" href="/profile" use:link>
				<i class="fas fa-user"></i>
				{$t('menu.settings')}
			</a>
			<button class="dropdown-item" on:click={User.logout}>
				{#if localStorage.getItem('sudo_token')}
					<i class="fa-solid fa-xmark"></i>
					{$t('login.action.exitSudo')}
				{:else}
					<i class="fas fa-sign-out-alt"></i>
					{$t('login.action.logout')}
				{/if}
			</button>
		</div>
	{/if}
</button>

<style>
	.nav-item {
		padding: 0 0 0 1rem;
		cursor: pointer;
		color: #ced4da;
		user-select: none;
		display: flex;
		align-items: center;
		gap: .2rem;


		background-color: transparent;
		border: none;
	}
	.nav-item:hover {
		color: #fff;
	}

	span {
		vertical-align: middle;
		margin-right: 0.6rem;
		font-size: 0.9rem;
		text-wrap: nowrap;
	}
	img {
		vertical-align: middle;
		height: 2rem;
		width: 2rem;
		object-fit: cover;
		margin-right: 0.6rem;
		border-radius: 50%;
	}

	.dropdown {
		position: fixed;
		top: 4rem;
		right: 1rem;
		background: var(--var-color-background-dark);
		padding: 0;
		border-radius: 5px;
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 60%);
		animation: fadeIn 0.1s ease-out;
	}

	.dropdown-item {
		width: 10rem;
		background: transparent;
		border: none;
		cursor: pointer;
		color: #ced4da;
		font-size: 1rem;
		padding: 0.7rem 1rem;
		display: block;
		text-decoration: none;
		text-align: left;
		border-top: 1px solid var(--var-color-border);
		box-sizing: border-box;
	}
	.dropdown-item:hover {
		background: var(--var-color-background);
		color: #fff;
	}
	.dropdown-item:first-child {
		border-top: none;
	}
	.dropdown-item i {
		margin-right: 0.5rem;
	}

	.nav-item img {
		background: var(--var-color-border);
	}

	.nav-item.mobile {
		margin: 0;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(-.5rem);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>
