<script lang="ts">
    import Api from "../Api";
	import Loading from "../layout/Loading.svelte";
	import Modal from "../layout/Modal.svelte";
    import { User } from "../store";
    import { error, success } from "../toaster";
	import SupportLink from "./SupportLink.svelte";

	let loading = false;

	async function accept() {
		loading = true;
		try {
			const response = await Api.call('settings/tos/agree');
			if (response.success) {
				await User.fetch();
				success('Souhlas uložen');
			} else {
				error(response.message ?? 'Nastala nečekaná chyba');
			}
		} catch (err) {
			error(err.message);
		}
		finally {
			loading = false;
		}
	}
</script>

{#if $User && !$User.tos_agreed}
	<Modal open={true} canClose={false}>
		<h2>Aktualizace obchodních podmínek</h2>
		{#if loading}
			<Loading />
		{:else}
			<p>
				Vážený zákazníku,<br />
				od Vaší poslední návštěvy jsme aktualizovali naše
				<a href="https://storage.helkor.eu/system/docs/obchodni-podminky.pdf" target="_blank">obchodní podmínky</a
				>.
			</p>
			<p>
				Tímto Vás prosíme, aby jste se s těmito novými podmínkami seznámil.
			</p>
			<p>
				Pokud jste již s podmínkami obeznámen a souhlasíte s nimi, klikněte
				na tlačítko níže.
			</p>
			<p>
				V případě nesouhlasu máte právo <SupportLink
					>kontaktovat naší podporu</SupportLink
				> a ukončit smlouvu.
			</p>
			<div>
				<button class="button small right" on:click={accept}> Souhlasím s podmínkami </button>
			</div>
		{/if}
	</Modal>
{/if}

<style>
	p {
		color: var(--var-color-text-light);
	}
</style>
