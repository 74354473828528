<script lang="ts">
	export let value: number = 0;
	export let min: number = 0;
	export let max: number = 100;
	export let step: number = 1;
</script>

<div class="slider-wrapper">
	<input class="slider" type="range" bind:value {min} {max} {step} on:input on:change />
</div>

<style>
	.slider-wrapper {
		width: 100%;
		height: 1.5rem;
		display: flex;
		align-items: center;
	}

	.slider {
		padding: 0;
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		height: .3rem !important;
		background-color: rgba(105, 105, 105, 0.1);
		outline: none;
		border-radius: 1rem;
		border: none;
		-webkit-transition: .2s;
		transition: background-color .2s;
		cursor: pointer;
	}

	.slider:hover {
		background-color: rgba(165, 165, 165, 0.1);
	}

	.slider::-webkit-slider-thumb {
		-webkit-appearance: none; /* Override default look */
		appearance: none;
		width: 1rem;
		height: 1rem;
		background-color: var(--var-color-primary);
		cursor: pointer; /* Cursor on hover */
		border-radius: 1rem;
		transition: width .1s ease, height .1s ease;
	}

	.slider::-moz-range-thumb {
		width: 1rem; /* Set a specific slider handle width */
		height: 1rem; /* Slider handle height */
		background-color: var(--var-color-primary);
		cursor: pointer; /* Cursor on hover */
		transition: width .1s ease, height .1s ease;

	}
	.slider:hover::-webkit-slider-thumb {
		width: 1.3rem;
		height: 1.3rem;
	}
	.slider:hover::-moz-range-thumb {
		width: 1.3rem;
		height: 1.3rem;
	}
</style>
