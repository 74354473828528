<script lang="ts">
	import { SvelteToast } from "@zerodevx/svelte-toast";
	import { Router, Route } from "svelte-routing";
	import AppLoading from "./layout/AppLoading.svelte";
	import LoginForm from "./page/LoginForm.svelte";
	import RecoveryForm from "./page/RecoveryForm.svelte";
	import RegisterForm from "./page/RegisterForm.svelte";
	import { User, UserLoading } from "./store";
	import HomePage from "./page/home/HomePage.svelte";
	import Redirect from "./Redirect.svelte";
	import DialogLayer from "./lib/DialogLayer.svelte";
	import ReferralHandle from "./lib/ReferralHandle.svelte";
	import NotFoundPage from "./page/NotFoundPage.svelte";
	import AdminRouter from "./admin/AdminRouter.svelte";
	import TosGuard from "./lib/TosGuard.svelte";
	import DynamicRouteArray from "./lib/DynamicRouteArray.svelte";
	import Api from "./Api";
	import JobLayer from "./lib/JobLayer.svelte";
	import Protect from "./lib/Protect.svelte";
	import { UserRole } from "./types/Enums";
	import HelpCenter from "./lib/HelpCenter.svelte";
	import LayoutContext from "./layout/layout-context/LayoutContext.svelte";
	import { Layout, setLayout } from "./layout/layout-context/layout-context";
	import OrderPage from "./page/order/OrderPage.svelte";
	import { IsLocaleLoaded } from "./lib/locale";
	import { notypecheck } from "./utils/notypecheck";
	import { onMount } from "svelte";
	import { timeMonitor } from "./lib/time-monitor";
  import OrderVpsPage from "./page/order/vps/OrderVpsPage.svelte";
	// import CommandPaletteContainer from "./lib/command-palette/CommandPaletteContainer.svelte";

	let appLoading = true;
	let userLoading = false;

	UserLoading.subscribe(x => {
		userLoading = x && !$User;
		if (!userLoading) {
			appLoading = false;
		}
	});
	let updateUserAntispamGuard = false;
	let updeteUserAntispamGuartTriggered = false;
	function shouldUpdateUser(){
		if (updateUserAntispamGuard) {
			updeteUserAntispamGuartTriggered = true;
			return;
		}
		User.fetch();
		updateUserAntispamGuard = true;
		setTimeout(()=>{
			updateUserAntispamGuard = false;
			if (updeteUserAntispamGuartTriggered) {
				updeteUserAntispamGuartTriggered = false;
				User.fetch();
			}
		}, 1000*10);
	}
	setInterval(shouldUpdateUser, 1000 * 60 * 5);

	const routes = ([
		['/credit', () => import('./page/credit/CreditPage.svelte')],
		['/reedem/:voucher', () => import('./page/credit/CreditPage.svelte')],
		['/profile', [
			['/link', [
				['/minecraft-java', () => import('./page/LinkMinecraftAccountPage.svelte'), Layout.NONE],
			]],
			['', () => import('./page/ProfilePage.svelte')]
		]],
		['/domain', [
			['s/new',() => import("./page/domains/onboarding/DomainAddPage.svelte")],
			['s',() => import("./page/domains/DomainListPage.svelte")],
			['/:id',[
				['/proxy', () => import('./page/domains/proxy/DomainProxyPage.svelte')],
				['', () => import("./page/domains/DomainPage.svelte")]
			]],
		]],
		['/oauth2', [
			['/authorize', () => import('./page/oauth2/Oauth2Authorize.svelte'), Layout.LOGIN],
		]],
		['/servers', [
			['/create/:id', () => import('./page/CatalogCategoryPage.svelte')],
			['/create',	 () => import('./page/CatalogCategoryListPage.svelte')],
			['', () => import('./page/ServerListPage.svelte')],
		]],
		['/server/:identifier', [
			['/terminal', () => import("./page/TerminalPage.svelte")],
			['/schedules', () => import("./page/server/cron/ServerSchedulePage.svelte")],
			['/databases', () => import("./page/ServerDatabasePage.svelte")],
			['/files', [
				['/usage', () => import('./page/server/files/ServerDiskSpaceUsage.svelte')],
				['/edit', () => import('./page/ServerEditFilePage.svelte')],
				['/view-image', () => import('./page/ServerFilePageViewImage.svelte')],
				['/', () => import("./page/ServerFilePage.svelte")],
			]],
			['/settings', () => import('./page/ServerSettingsPage.svelte')],
			['/users', () => import('./page/ServerSubuserPage.svelte')],
			['/backups', () => import('./page/ServerBackupPage.svelte')],
			['/is_transferring', () => import('./page/ServerTransferingPage.svelte')],
			['/marketplace', () => import('./page/ServerMarketPage.svelte')],
			['/minecraft', [
				['/addons', () => import("./page/server/minecraft/minecraft-addons/MinecraftAddonPage.svelte")],
				['/version', () => import('./page/server/minecraft/version/MinecraftVersionPage.svelte')],
				['/server-list', () => import('./page/ServerMinecraftList.svelte')],
				['/server-properties', () => import('./page/ServerMinecraftProperties.svelte')],
				['/import', () => import('./page/server/minecraft/import/ServerMinecraftImportPage.svelte')],
			]],
			['', () => import("./page/ServerMainPage.svelte")],
		]],
		['/marketplace/:market_id', () => import('./page/ServerMarketPage.svelte')],
		['/vps', [
			['/:identifier', [
				['/vnc', () => import('./page/vps/vnc/VpsVncPage.svelte')],
				['/security', () => import('./page/vps/security/VpsSecurityPage.svelte')],
				['/network', () => import('./page/vps/network/VpsNetworkPage.svelte')],
				['/reinstall', () => import('./page/vps/reinstall/VpsReinstallPage.svelte')],
				['/billing', () => import('./page/vps/billing/VpsBillingPage.svelte')],
				['', () => import('./page/vps/status/VpsStatusPage.svelte')],
			]],
			['', () => import('./page/vps/VpsListPage.svelte')],
		]],
	] as RouterRecord[]);

	if (Api.environment.debug) {
		routes.push(['/sandbox', () => import('./page/SandboxPage.svelte')]);
		const iconLink = document.querySelector('link[rel=icon]');
		if (iconLink) {
			(iconLink as any).href = '/img/localhost_icon.png';
		}
	}

	onMount(() => {
		timeMonitor.stop('app-init')
	});
</script>

<svelte:window on:focus={shouldUpdateUser} />

{#if appLoading || !$IsLocaleLoaded}
	<AppLoading />
{:else}
<!-- <CommandPaletteContainer> -->
	<Router>
		<LayoutContext>
			{#if !$User}
				<Route path="/reset-password">
					{@const _ = setLayout(Layout.LOGIN)}
					<RecoveryForm />
				</Route>
				<Route path="/register">
					{@const _ = setLayout(Layout.LOGIN)}
					<RegisterForm />
				</Route>
				<Route path="/ref/:code" component={ReferralHandle} />

				<!-- <Route path="/order" component={OrderPage} layout={Layout.ANONYMOUS} /> -->
				<Route path="/order/vps" {...notypecheck({ component: OrderVpsPage, layout: Layout.ANONYMOUS })} />
				<Route path="/order/:packageId" {...notypecheck({ component: OrderPage, layout: Layout.ANONYMOUS })} />

				<Route>
					{@const _ = setLayout(Layout.LOGIN)}
					<LoginForm />
				</Route>
			{:else}
				<Route path="/login"          component={Redirect} to='/' replace={true} />
				<Route path="/register"       component={Redirect} to='/' replace={true} />
				<Route path="/reset-password" component={Redirect} to='/' replace={true} />
				<Route path="/ref/:code" component={ReferralHandle} />

				<!-- <Route path="/order" component={OrderPage} layout={Layout.MAIN} /> -->
				<Route path="/order/vps" {...notypecheck({ component: OrderVpsPage, layout: Layout.MAIN })} />
				<Route path="/order/:packageId" {...notypecheck({ component: OrderPage, layout: Layout.MAIN })} />

				<DynamicRouteArray {routes} />

				<Route path="/" exact>
					<HomePage />
				</Route>

				<Protect roles={[
					UserRole.ADMIN,
				]}>
					<AdminRouter />
				</Protect>

				<Route component={NotFoundPage} />
				<TosGuard />
			{/if}
		</LayoutContext>
	</Router>

	<SvelteToast />
	<DialogLayer />
	<JobLayer />
	<HelpCenter />


<!-- </CommandPaletteContainer> -->
{/if}
