import Api, { ApiActiveTransferCount } from "../Api";
import { domainRegex } from "../utils/regex";
import { cached } from "./cached-function";
import { ReverseProxyRecordType, type DomainConfig, type DomainLogRecord, type ReverseProxyRecord } from "./domain.types";

function getProxyServer() {
	return Api.environment.proxyServerURL;
}

export const domainPattern = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const subdomainPattern = /^[a-z0-9-.]{0,61}$/;
export const proxyIP = '5.39.203.2';

export const proxyRecordTypes: Record<ReverseProxyRecordType, string> = {
	[ReverseProxyRecordType.HTTP_PROXY]: 'Služba',
	[ReverseProxyRecordType.REDIRECT]: 'Přesměrování',
};

type ProxyServerResponse<T> = {
	error?: string;
	data: T;
};

async function request<T>(method: string, endpoint: string, body: any = undefined): Promise<ProxyServerResponse<T>> {
	ApiActiveTransferCount.update(n => n + 1);
	try {
		const req = await fetch(`${getProxyServer()}api/${endpoint}`, {
			method: method,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': Api.getToken() ?? "",
			},
			body: body ? JSON.stringify(body) : undefined,
		});

		const res = await req.json();

		if (res.error) {
			throw new Error(res.error);
		}

		return res;
	} finally {
		ApiActiveTransferCount.update(n => n - 1);
	}
}

export async function createDomainConfig(domain: string) {
	if (!domainRegex.test(domain)) {
		throw new Error("Invalid domain name");
	}
	return await request<DomainConfig>('POST', 'domain', { domain });
}

export async function deleteDomainConfig(id: string) {
	return await request<DomainConfig>('DELETE', `domain/${id}`);
}

export async function listDomainConfigs() {
	return await request<DomainConfig[]>('GET', 'domain');
}

export async function getDomainConfig(id: string) {
	return await request<DomainConfig>('GET', `domain/${id}`);
}

export async function requestDomainVerification(id: string) {
	return await request<DomainConfig>('POST', `domain/${id}/verify`);
}

export async function listProxyRecords(domainId: string) {
	return await request<ReverseProxyRecord[]>('GET', `domain/${domainId}/proxy`);
}

export async function getDomainLog(domainId: string) {
	return await request<DomainLogRecord[]>('GET', `domain/${domainId}/logs`);
}

export async function updateProxyRecord(record: Partial<ReverseProxyRecord>) {
	if (!record.id || !record.domainId) {
		throw new Error("id and domainId must be set");
	}

	return await request<ReverseProxyRecord>('PUT', `domain/${record.domainId}/proxy`, record);
}

export async function deleteProxyRecord(domainId: string, id: string) {
	return await request<ReverseProxyRecord>('DELETE', `domain/${domainId}/proxy`, { id });
}

interface CreateProxyRecordRequestBody {
	type: ReverseProxyRecordType;
	subdomain: string;
	target: string;
	forceSSL: boolean;
}
export async function createProxyRecord(domainId: string, data: CreateProxyRecordRequestBody) {
	return await request<ReverseProxyRecord>('POST', `domain/${domainId}/proxy`, data);
}


export type AllocationGroup = {
	name: string;
	allocations: ServerAllocation[];
}
async function _findProxyTargets(): Promise<AllocationGroup[]> {
	const serverListResponse = await Api.call('servers');
	if (!serverListResponse.success) {
		return [];
	}
	const servers: ServerInfo[] = serverListResponse.servers;
	const allocationGroupPromises = servers.map(async (server): Promise<AllocationGroup | null> => {
		const allocationListResponse = await Api.call(`server/${server.identifier ?? server.id}/ports`);
		if (!allocationListResponse.success) {
			return null;
		}

		const allocations: ServerAllocation[] = allocationListResponse.ports;
		if (allocations.length === 0) {
			return null;
		}

		return {
			name: server.name,
			allocations
		};
	});

	const allocationGroups = await Promise.all(allocationGroupPromises);
	return allocationGroups.filter(Boolean) as AllocationGroup[];
}
export const findProxyTargets = cached(_findProxyTargets, 1000 * 60);

export const allocationToHttpString = (allocation: ServerAllocation) => `http://${allocation.ip_alias || allocation.ip}:${allocation.port}/`;
