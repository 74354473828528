<script lang="ts">
	import Api from '../../Api';
	import Loading from '../../layout/Loading.svelte';
	import { t } from '../../lib/locale';

	let loading = false;
	let changelogs: InfoboardMessage[] = [];

	async function updateChangelog()
	{
		loading = true;
		try {
			const response = await Api.call('infoboard', {}, "POST", 600);

			if (!response.success) {
				return;
			}

			changelogs = (response.messages as InfoboardMessage[])
				.filter(changelog => new Date(changelog.event_end ?? changelog.event_start ?? changelog.created_at).getTime() > new Date().getTime() - 1000 * 60 * 60 * 24 * 28)
				.sort((a,b) => {
					return new Date(b.event_start ?? b.event_end ?? b.created_at).getTime() - new Date(a.event_start ?? a.event_end ?? a.created_at).getTime();
				});
		} finally {
			loading = false;
		}
	}
	updateChangelog();

	// Date functions
	function isDateOnly(time: string): boolean {
		const date = new Date(time);
		return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
	}
	function isSameDateAs(time1: string, time2: string): boolean {
		const date1 = new Date(time1);
		const date2 = new Date(time2);
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
	}
	function toLocaleDateString(time: string): string {
		return new Date(time).toLocaleDateString();
	}
	function toLocaleTimeString(time: string): string {
		const str = new Date(time).toLocaleTimeString();
		return str.substring(0, str.length - 3);
	}
	function toLocaleString(time: string): string {
		return toLocaleDateString(time) + ' ' + toLocaleTimeString(time);
	}

	// Info functions
	function isFuture(changelog: InfoboardMessage): boolean {
		return new Date(changelog.event_start ?? changelog.event_end ?? changelog.created_at) > new Date();
	}
	function isCurrent(changelog: InfoboardMessage): boolean {
		if (changelog.event_start && changelog.event_end) {
			return new Date(changelog.event_start) < new Date() && new Date(changelog.event_end) > new Date();
		}
		return false;
	}
	function isPast(changelog: InfoboardMessage): boolean {
		return new Date(changelog.event_end ?? changelog.event_start ?? changelog.created_at) < new Date();
	}
</script>

{#if loading}
	<Loading />
{:else if changelogs != null}
	<div>
		{#if changelogs.length == 0}
			<p style="text-align: center; color: var(--var-color-text-light);">{$t('infoboard.empty')}</p>
		{/if}
		{#each changelogs as changelog}
			<div
				class="changelog"
				class:important={changelog.important}
				class:isFuture={isFuture(changelog)}
				class:isCurrent={isCurrent(changelog)}
				class:isPast={isPast(changelog)}
			>
				<div>
					<span class="date">
						{#if !changelog.event_start && !changelog.event_end}
							{toLocaleDateString(changelog.created_at)}
						{:else if changelog.event_start && !changelog.event_end}
							{#if isDateOnly(changelog.event_start)}
								{toLocaleDateString(changelog.event_start)}
							{:else}
								{toLocaleString(changelog.event_start)}
							{/if}
						{:else if !changelog.event_start && changelog.event_end}
							{#if isDateOnly(changelog.event_end)}
								do {toLocaleDateString(changelog.event_end)}
							{:else}
								do {toLocaleString(changelog.event_end)}
							{/if}
						{:else}
							{#if isDateOnly(changelog.event_start) && isDateOnly(changelog.event_end)}
								{toLocaleDateString(changelog.event_start)} - {toLocaleDateString(changelog.event_end)}
							{:else if isSameDateAs(changelog.event_start, changelog.event_end)}
								{toLocaleString(changelog.event_start)} - {toLocaleTimeString(changelog.event_end)}
							{:else}
								{toLocaleString(changelog.event_start)} - {toLocaleString(changelog.event_end)}
							{/if}
						{/if}
					</span>
					<span class="title">{changelog.title ?? ''}</span>
				</div>
				<p>
					{#each (changelog.content ?? '').split('\n') as line}
						{#if line.length > 0}
							<span class="line">{line}</span>
						{:else}
							<br />
						{/if}
					{/each}
				</p>
				{#if changelog.link}
					<div class="link">
						<a href={changelog.link} target="_blank">
							{changelog.link}
						</a>
						<a href={changelog.link} target="_blank">
							<i class="fas fa-external-link-alt"></i></a>
					</div>
				{/if}
			</div>
		{/each}
	</div>
{/if}

<style lang="scss">
	.changelog {
		border-bottom: 1px solid var(--var-color-border);
		padding: 0.5rem;
	}
	.changelog:last-of-type {
		border-bottom: none;
	}

	.changelog.important {
		box-shadow: -0.9rem 0 var(--var-color-background-dark), -1rem 0 var(--var-color-primary);
	}

	.title {
		margin: 0.5rem 0;
		display: block;
		font-weight: bold;
	}
	.date {
		float: right;
		color: var(--var-color-text-light);
	}
	.changelog p {
		margin: 0.5rem 0 0.5rem 0;
		color: var(--var-color-text-light);
	}

	.changelog:last-of-type p {
		margin-bottom: 0;
	}

	.line {
		display: block;
		min-height: 1rem;
	}

	.link {
		margin-top: .5rem;
		a {
			color: var(--var-color-text-light)
		}
	}

	.changelog.isFuture + .changelog.isPast, .changelog.isCurrent + .changelog.isPast {
		border-top: 1px solid var(--var-color-primary);
		position: relative;

		&::before {
			content: '▴ Nadcházející výše ▴';
			display: block;
			text-align: center;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -1.2rem);
			background: var(--var-color-background-dark);
			padding: 0 .5rem;
			color: var(--var-color-primary);
		}
	}
</style>
