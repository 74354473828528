<script lang="ts">
</script>

<div class="loading">
	<div class="spin">
		<div class="circle"></div>
		<div class="circle red"></div>
		<br />
		<div class="circle white"></div>
		<div class="circle"></div>
	</div>
</div>

<style>
	.loading {
		display: block;
		text-align: center;
		margin: 1rem 0;
	}

	.circle {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		background: transparent;
		margin: 0.1rem;
		border-radius: 50%;
	}
	.circle.red {
		background: var(--var-color-primary);
	}
	.circle.white {
		background: white;
	}

	.spin {
		display: inline-block;
		animation: spin 1.3s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
