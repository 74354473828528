<script lang="ts">
	import { navigate } from "svelte-routing";
	import ProfilePictureManager from "../../lib/ProfilePictureManager";
	import { verifyEmail } from "../../lib/email-verify";
	import { User } from "../../store";
	import { UserRole } from "../../types/Enums";
	import { success } from "../../toaster";
	import Modal from "../../layout/Modal.svelte";
	import LoginForm from "../LoginForm.svelte";
	import SelectUser from "../../lib/SelectUser.svelte";
	import { t } from "../../lib/locale";

	export let value: number | undefined = undefined;

	let loginFormIsOpen = false;
	function loginForm() {
		loginFormIsOpen = true;
	}

	function registerForm() {
		success("Formulář uložen, po registraci vás na něj vrátíme");
		navigate(`/register?back=${encodeURIComponent(window.location.pathname)}`);
	}
</script>

<h2>{$t('order.field.user.title')}</h2>
{#if !$User}
	<p class="info">
		{$t('order.field.user.needLogin')}
	</p>
	<div class="account">
		<div class="account-actions">
			<button
				class="button"
				on:click={loginForm}
			>
				<i class="fa-solid fa-sign-in"></i>
				{$t('login.action.login')}
			</button>
			<button
				class="button"
				on:click={registerForm}
			>
				<i class="fa-solid fa-user-plus"></i>
				{$t('login.action.register')}
			</button>
		</div>
	</div>

	<Modal bind:open={loginFormIsOpen}>
		<h2 style="text-align: center;">{$t('login.action.login')}</h2>
		<LoginForm />
	</Modal>
{:else if $User.role === UserRole.ADMIN}
	<p class="info">
		{$t('order.field.user.adminInfo')}
	</p>
	<SelectUser bind:value={value} />
{:else}
	{@const _ = value = $User.id}
	<p class="info">
		{$t('order.field.user.info')}
	</p>
	<div class="account">
		<div class="account-avatar">
			<img src={ProfilePictureManager.getAvatar($User)} alt="Avatar" />
		</div>
		<div class="account-info">
			<div class="account-name">{$User.username}</div>
			<div class="account-email">
				{$User.email}
			</div>
		</div>
		<div class="account-actions">
			{#if !$User.email_verified}
				<button
					class="button primary"
					aria-label="Odešleme Vám ověřovací e-mail"
					data-balloon-pos="up"
					on:click={verifyEmail}
				>
					<i class="fa-solid fa-check"></i>
					{$t('login.action.verify_email')}
				</button>
			{/if}
			<button class="button" on:click={User.logout}>
				<i class="fa-solid fa-sign-out"></i>
				{$t('login.action.logout')}
			</button>
		</div>
	</div>
	{#if !$User.email_verified}
		<p class="need-email-verify">
			{$t('order.field.user.needEmailVerify')}
		</p>
	{/if}
{/if}

<style>
	.account {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		align-items: center;
		margin-bottom: .5rem;
		margin-top: .5rem;
	}

	.account-avatar img {
		width: 3rem;
		height: 3rem;
		object-fit: cover;
		border-radius: 50%;
	}

	.account-info {
		display: flex;
		flex-direction: column;
		margin-right: auto;
	}

	.account-name {
		font-weight: 600;
		font-size: 1.3rem;
	}
	.account-email {
		font-size: .9rem;
		color: var(--var-color-text-light);
	}

	.account-actions {
		display: flex;
		flex-direction: row;
		gap: .75rem;
	}

	.button {
		display: flex;
		align-items: center;
		gap: .5rem;
		padding: .5rem 1rem;
		border-radius: 5px;
		background: var(--var-color-border);
		border: none;
		color: var(--var-color-text-light);
		cursor: pointer;
		width: auto;
		text-decoration: none;
	}
	.button:hover {
		color: white;
	}

	.button.primary {
		background: var(--var-color-primary);
		color: white;
	}
	.button.primary:hover {
		background: var(--var-color-primary-hover);
	}

	p.info {
		margin-top: 0;
		margin-bottom: 1.5rem;
		color: var(--var-color-text-light);
	}

	.need-email-verify {
		color: var(--var-color-primary);
	}
</style>
